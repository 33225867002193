<template>
  <v-card>
    <v-tabs v-model="tab" class="deep-gl" left dark icons-and-text>
      <v-tabs-slider></v-tabs-slider>

      <v-tab href="#tab-1">
        <v-toolbar-title>
          {{ $t("MAPPINGS.LIST") }}
        </v-toolbar-title>
      </v-tab>
      <v-spacer></v-spacer>

      <div class="buttons-tab">
        <v-btn
          class="mr-4"
          v-on:click="downloadTemplate()"
          v-if="hasPermissions('mappings-create')"
          >Download Template</v-btn>
        <v-btn
          color="mr-4"
          v-on:click="importMapping()"
          v-if="hasPermissions('mappings-create')"
          >Import Mapping</v-btn
        >
        <v-btn
          color="mr-4 orange"
          v-on:click="createMapping()"
          :loading="isProcessing"
          v-if="hasPermissions('mappings-create')"
          >Add Mapping</v-btn
        >
         <input
            ref="uploader"
            class="d-none"
            type="file"
            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            @change="upload"
        >
      </div>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item value="tab-1">
        <v-card flat>
          <v-card-text>
            <Table
              :path="path"
              tableId="mappings"
              dbTable="mappings"
              :tableInit="tableInit"
              :showParams="showParams"
              :filtersFields="filtersFields"
              @showDetails="$router.push({
                name: 'edit-mapping-values',
                params: {
                  mappingId: $event.mapping_id
                }
              })"
            ></Table>
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import { TABLE_INIT_URL, GET_MAPPINGS_INIT,UPLOAD_MAPPING,DOWNLOAD_MAPPING } from "@/store/mappings.module";
import { SHOW_SNACK } from "@/store/snack.module";
import Table from "@/views/partials/table/Table.vue";

export default {
  components: {
    Table,
  },
  name: "ShowAliases",
  data() {
    return {
        isProcessing: false,
        path: TABLE_INIT_URL,
        tableInit: GET_MAPPINGS_INIT,
        tab: "tab-1",
        showParams: { name: "edit-mappings", key: "id" },
        filtersFields: [],
        file:null,
        url:null
    };
  },
  methods:{
    createMapping() {
     this.$router.push({ name: "create-mappings"});
    },
    importMapping(){
        this.$refs.uploader.click();
    },
    downloadTemplate(){
         this.$store
        .dispatch(DOWNLOAD_MAPPING)
        .then((resource) => {
            location.href = resource.url;
        })
        .catch((response) => {
        })
        .finally(() => {
          this.isProcessing = false;
        });
    },
     upload(e){
        this.file = e?e.target.files[0]:this.file;
        if(!this.file){
            return;
        }
        let formData = new FormData();
        formData.append('file', this.file);
        this.isProcessing = true;
         this.$store
        .dispatch(UPLOAD_MAPPING, {params:formData})
        .then((resource) => {
          this.$store.dispatch(SHOW_SNACK, {
            message: "Mapping upload successfuly.",
          });
        })
        .catch((response) => {
        })
        .finally(() => {
          this.isProcessing = false;
        });
    },
  },
};
</script>

<style>
</style>